import React, { useRef, useState } from "react";
// import { SERVER_URL } from "../../func/constants";
import $ from "jquery";
// import { copyToBuffer } from "../../func/common";
import api from "../func/api";
import { ReactSortable } from "react-sortablejs";
import PopupEditMedia from "./PopupEditMedia";
import * as ButtonS from "semantic-ui-react";

const SERVER_URL = "https://api.cms.motorfirst.ru";

const { Button, Image, Label, Icon } = ButtonS;

const ManyMedia = ({
  pics,
  name,
  data,
  setData,
  setEditMedia,
  isModeration,
  deactiveFields,
}) => {
  const [showURL, setShowURL] = useState(false);
  const [editPic, setEditPic] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(false);
  const [previewZoom, setPreviewZoom] = useState(null);

  const deletePic = (i) => {
    pics.splice(i, 1);
    data.kontent.foto = pics;
    setData({ ...data });
  };

  const changeStatusModerationFoto = (e, picId) => {
    if (data.kontent.moderaciya === undefined)
      data.kontent.moderaciya = { foto: {} };
    else if (data.kontent.moderaciya.foto !== undefined) {
      const newStatus = {};
      pics.forEach(
        (pic) => (newStatus[pic._id] = data.kontent.moderaciya.foto),
      );
      data.kontent.moderaciya.foto = newStatus;
    }
    data.kontent.moderaciya.foto[picId] = e.currentTarget.checked;
    setData({ ...data });
  };

  const previewPhotoHTMl = pics?.map((pic, i) => {
    const srcPic = SERVER_URL + pic.url + "?v=" + new Date().getTime();
    return (
      <div
        className="col col-4 d-flex preview-photo__elem"
        key={i}
        onClick={() => {
          setPreviewZoom(srcPic);
        }}
      >
        <div className="position-relative w-100">
          <img
            src={srcPic}
            className="border w-100 m-auto"
            style={{
              backgroundPosition: "center",
            }}
            alt=""
          />
          <div
            className="position-absolute"
            style={{ top: "5px", left: "5px" }}
          >
            <span className="badge bg-primary d-block mb-1">
              {pic.width === pic.height ||
              +pic.width + 1 === +pic.height ||
              +pic.width - 1 === +pic.height
                ? pic.width
                : `${pic.width}x${pic.height}`}
            </span>
            <span className="badge bg-secondary d-block">
              {pic.size
                ? pic.size / 1024 < 1024
                  ? `${(pic.size / 1024).toFixed(2)} KB.`
                  : `${(pic.size / (1024 * 1024)).toFixed(2)} MB.`
                : "< 1 KB."}
            </span>
          </div>
        </div>
      </div>
    );
  });

  const picsHTML = pics?.map((pic, i) => {
    return (
      <ManyMediaElement
        pic={pic}
        i={i}
        key={pic.hash + i}
        deletePic={deletePic}
        setEditPic={setEditPic}
        isModeration={isModeration}
        changeStatusModerationFoto={changeStatusModerationFoto}
        data={data}
        deactiveFields={deactiveFields}
      />
    );
  });

  const saveEditPic = (newPic, i) => {
    data.kontent.foto[i] = newPic;
    setData({ ...data });
  };
  const closeEditPic = () => {
    setEditPic(null);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <Button>Click Here</Button>
        <Image>Click Here</Image>
        <Label>
          {" "}
          <Icon name="mail" /> 23
        </Label>
        <h6 className="mb-3">
          {name}{" "}
          {pics !== undefined && pics.length > 0 && (
            <button
              className="btn btn-outline-primary ms-3 py-1"
              onClick={() => setPreviewPhoto(!previewPhoto)}
            >
              Просмотр
            </button>
          )}
        </h6>

        {isModeration && (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              checked={
                data.kontent.moderaciya !== undefined &&
                data.kontent.moderaciya?.foto === true
              }
              onChange={(e) => {
                if (data.kontent.moderaciya === undefined)
                  data.kontent.moderaciya = {};
                data.kontent.moderaciya.foto = e.currentTarget.checked;
                setData({ ...data });
              }}
            ></input>
          </div>
        )}
      </div>
      {pics !== undefined && pics.length > 0 && (
        <div className={`row preview-photo ${previewPhoto ? "active" : ""}`}>
          {previewPhotoHTMl}
          <div
            className={`preview-overlay ${
              previewZoom === null ? "" : "active"
            }`}
            style={{ overflowY: "auto" }}
            onClick={(e) => {
              if (e.currentTarget === e.target) {
                setPreviewZoom(null);
              }
            }}
          >
            <img
              src={previewZoom}
              alt=""
              style={{ maxWidth: "80%", maxHeight: "80%", margin: "auto" }}
            ></img>
          </div>
        </div>
      )}
      <ReactSortable
        animation={200}
        list={pics}
        setList={(newOrderArr) => {
          //   setOrderMenu(newOrderArr);
          if (!deactiveFields) {
            data.kontent.foto = newOrderArr;
            setData({ ...data });
          }
        }}
        group="shared"
        className="row"
        handle=".bi-list"
      >
        {picsHTML}
      </ReactSortable>

      {!isModeration && !deactiveFields ? (
        showURL ? (
          <div className="input-group mt-3 mb-3">
            <input type="text" className="form-control" />
            <span className="input-group-text pointer">Upload</span>
            <span
              className="input-group-text pointer"
              onClick={() => {
                setShowURL(false);
              }}
            >
              <i className="bi bi-x" />
            </span>
          </div>
        ) : (
          <button
            type="button"
            className="btn btn-primary w-100 d-flex justify-content-between my-2"
            onClick={(e) => {
              $(e.target).find('input[type="file"]').click();
            }}
          >
            <span> Загрузить</span>

            <input
              type="file"
              name="uploadImg"
              accept="image/jpeg,image/png,image/svg+xml"
              style={{ visibility: "hidden" }}
              multiple
              onInput={(e) => {
                if (e.target.files.length) {
                  const formData = new FormData();
                  Object.keys(e.target.files).forEach((num) => {
                    formData.append("uploadImg", e.target.files[num]);
                  });

                  let xhr = new XMLHttpRequest();

                  xhr.onloadend = () => {
                    if (xhr.status == 200) {
                      console.log("Успех");
                      if (!data.kontent.foto) {
                        data.kontent.foto = [];
                      }
                      data.kontent.foto.push(...JSON.parse(xhr.response));
                      setData({ ...data });
                    } else {
                      console.log("Ошибка " + this.status);
                    }
                  };

                  xhr.open("POST", `${SERVER_URL}/main/upload-media`);
                  xhr.send(formData);
                }
              }}
            />
            <i className="bi bi-plus"></i>
          </button>
        )
      ) : null}
      {editPic !== null && (
        <PopupEditMedia
          save={saveEditPic}
          link={editPic.imgLink}
          id={editPic.pic._id}
          close={closeEditPic}
          i={editPic.i}
          active={editPic !== null}
        />
      )}
    </>
  );
};

const ManyMediaElement = ({
  pic,
  i,
  deletePic,
  setEditPic,
  isModeration,
  changeStatusModerationFoto,
  data,
  deactiveFields,
}) => {
  const imgLink = `${SERVER_URL + "/" + pic.url}`;
  const linkImg = useRef(null);
  return (
    <div className="col-4">
      <div className="card rounded p-3 bg-gray mb-2 border-0">
        <div
          className="rounded bg-white p-3"
          style={{
            backgroundImage: "url(" + imgLink + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: "150px",
          }}
        >
          {!isModeration && !deactiveFields && (
            <div className="d-flex flex-column align-items-end">
              <div
                className="icon-badge bg-white rounded text-center mb-1 pointer p-1"
                onClick={() => {
                  setEditPic({ pic, i, imgLink });
                }}
              >
                <i className="bi bi-pencil-fill" />
              </div>
            </div>
          )}
        </div>
        {/* <div className="d-flex border rounded progbar mt-2">
          <div className="rounded-start border w-50 bg-primary"></div>
        </div> */}
        <div className="input-group mt-2">
          <span className="input-group-text">Alt</span>
          <input
            type="text"
            className="form-control"
            defaultValue={pic.alt}
            onChange={(e) => {
              pic.alt = e.target.value;
            }}
            onBlur={() => {
              api
                .postAltMedia({ _id: pic._id, alt: pic.alt })
                .then((result) => {})
                .catch((err) => {});
            }}
            disabled={isModeration || deactiveFields}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center mt-3">
          <p className="m-0 me-auto">
            {pic.size
              ? pic.size / 1024 < 1024
                ? `${(pic.size / 1024).toFixed(2)} KB.`
                : `${(pic.size / (1024 * 1024)).toFixed(2)} MB.`
              : "< 1 KB."}
          </p>
          {isModeration && (
            <div className="form-check form-switch me-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                checked={
                  data.kontent.moderaciya !== undefined &&
                  (data.kontent.moderaciya.foto === true ||
                    data.kontent.moderaciya.foto[pic._id] === true)
                }
                onChange={(e) => {
                  changeStatusModerationFoto(e, pic._id);
                }}
              ></input>
            </div>
          )}
          <i
            className={`bi ${
              +pic.height === +pic.width ||
              +pic.height - 1 === +pic.width ||
              +pic.height + 1 === +pic.width
                ? "text-success bi-square-fill"
                : "text-danger bi-x-square-fill"
            } me-3`}
          ></i>
          {!deactiveFields && (
            <i
              className="bi bi-trash-fill text-danger me-3"
              onClick={() => {
                deletePic(i);
              }}
              style={{ cursor: "pointer" }}
            />
          )}
          <span className="text-secondary me-3">{i + 1}</span>
          <i
            className="bi bi-list"
            style={{
              cursor: "pointer",
              visibility: deactiveFields ? "hidden" : "visible",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ManyMedia;
