// import { observer } from "mobx-react";
import React, { useState, useRef, useEffect, useReducer } from "react";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router";
import api from "../func/api";
import $ from "jquery";
import TextBlock from "../modules/TextBlock";

const { Component } = React;

const orderMenu = [
  { icon: "", showIcon: true, link: "/constructor", name: "Конструктор" },
  { icon: "", showIcon: true, link: "/media", name: "Медиа" },
  {
    folder: true,
    name: "Страницы (Папка)",
    icon: "",
    showIcon: true,
    elems: [{ link: "/collections/${el.nameDB}", name: "el.name" }],
  },
];

const Login = (props) => {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");

  return (
    <div className="d-flex" style={{ height: "100vh", width: "100vw" }}>
      <div className="login d-flex m-auto">
        <div className="login-win">
          <div className="input-group mb-2">
            <input
              className="form-control"
              type="text"
              placeholder="Login"
              onChange={(e) => {
                setLogin(e.target.value);
              }}
            />
          </div>
          <div className="input-group mb-2">
            <input
              className="form-control"
              type="password"
              placeholder="password"
              onChange={(e) => {
                setPass(e.target.value);
              }}
            />
          </div>
          {/* <div className="form-check">
          <input
            className="form-check-input"
            id="flexCheckChecked"
            type="checkbox"
            value=""
            checked=""
          />
          <label className="form-check-label" for="flexCheckChecked">
            Запомнить меня{" "}
          </label>
        </div> */}
          <button
            className="btn btn-primary w-100"
            onClick={(e) => {
              api
                .login({ login, password: pass })
                .then((login) => {
                  if (login.status === 201) {
                    window.location.href = "/";
                  } else {
                    $(e.target).addClass("bg-danger");
                    setTimeout(() => {
                      $(e.target).removeClass("bg-danger");
                    }, 2500);
                  }
                })
                .catch((err) => {
                  console.log(`err`, err);
                  $(e.target).addClass("bg-danger");
                  setTimeout(() => {
                    $(e.target).removeClass("bg-danger");
                  }, 2500);
                });
            }}
          >
            Войти{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
