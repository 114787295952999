const IMAGE_URL = "https://api.cms.motorfirst.ru";
const colorsStatus = {
  Оплачен: "bg-success",
  "В работе": "bg-primary",
  "В очереди": "bg-secondary ",
  "На оплату": "bg-success",
  Исправить: "bg-warning",
  Модерация: "bg-info",
  Выполнено: "bg-success",
};
const SERVER_URL =
  process.env.REACT_APP_TYPE === "dev"
    ? "http://127.0.0.1:3211"
    : process.env.REACT_APP_TYPE === "test"
    ? "http://api.sexfirst.ru"
    : "https://api.motorfirst.ru";

export { IMAGE_URL, colorsStatus, SERVER_URL };
