import React, { useState, useRef, useEffect, Component } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
// import { changeData } from "../../func/common";
import edjsParser from "editorjs-parser";
import EditorJs from "react-editor-js";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import InlineCode from "@editorjs/inline-code";
import edjsHTML from "editorjs-html";

const customParsers = {
  customBlock: function (data, config) {
    return data.text;
  },
  image: function (data, config) {
    return data.text;
  },
  paragraph: function (data, config) {
    return data.text;
  },
  embed: function (data, config) {
    return data.text;
  },
  list: function (data, config) {
    return data.items;
  },
};

const parser = new edjsParser(undefined, customParsers);
const edjsParserHTML = edjsHTML();

const EDITOR_JS_TOOLS = {
  list: {
    class: List,
    inlineToolbar: true,
  },
  // linkTool: {
  //   class: LinkTool,
  //   inlineToolbar: true,
  // },
  // header: {
  //   class: Header,
  //   config: {
  //     levels: [2, 3],
  //     defaultLevel: 3,
  //   },
  //   inlineToolbar: true,
  // },
};

// import JoditEditor from "jodit-react";

const TextBlock = ({ data, setData, text, type, field, i, updFunk, block }) => {
  const textEditor = useRef(null);
  return (
    // <div id="textBlock"></div>
    <div className="text-edit">
      <EditorJs
        data={data?.kontent?.opisanie?.json}
        tools={EDITOR_JS_TOOLS}
        // enableReInitialize={true}
        onChange={async (e) => {
          const value = await textEditor.current.save();

          if (!data.kontent.opisanie) data.kontent.opisanie = {};
          data.kontent.opisanie.json = value;
          data.kontent.opisanie.lengthText = parser.parse(value).length;
          data.kontent.opisanie.html = edjsParserHTML.parse(value);

          setData({ ...data });

          //   changeData({
          //     data,
          //     value,
          //     type,
          //     field,
          //     i,
          //     updFunk,
          //     block,
          //   });
          // e.toolbar.open();
        }}
        onReady={(ed) => (textEditor.current = ed)}
      />
    </div>
  );
};

export default TextBlock;
