import React, { useState, useEffect, useCallback, useRef } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import api from "../func/api";

let crop = 0;
let cropTimer = null;

const cropInit = (elem) => {
  if (elem) {
    crop = new Cropper(elem, {
      aspectRatio: 1 / 1,
      preview: ".img-preview",
      crop: function (event) {},
      ready: function (e) {},
      responsive: true,
      dragMode: "move",
      cropBoxMovable: false,
      //   background: false,
    });
    crop.reset();
  } else {
    cropTimer = setTimeout(() => {
      cropInit(elem);
    }, 250);
  }
};

const PopupEditMedia = (props) => {
  const { close, link, save, elem } = props;
  const [loadingFetch, setLoadingFetch] = useState(false);

  const imgMain = useRef(null);

  useEffect(() => {
    if (link) {
      const imgHtml = document.querySelector(".media-block>img");
      cropInit(imgHtml);
    }

    return () => {
      if (crop) {
        crop.destroy();
      }
      if (cropTimer) {
        clearTimeout(cropTimer);
        cropTimer = null;
      }
    };
  }, [link]);

  return (
    <div
      class="modal fade show"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onMouseDown={(e) => {
        e.stopPropagation();

        if (e.currentTarget === e.target) close();
      }}
    >
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content p-3">
          <div class="row">
            <div class="col-8 modal-img">
              <div class="border h-100 media-block">
                <img src={link} ref={imgMain}></img>
              </div>
            </div>
            <div class="col-4 modal-tool">
              <div class="img-preview border mb-3"></div>
              <div class="img-btns">
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      crop.zoom(0.1);
                    }
                  }}
                >
                  Zoom in<i class="bi bi-zoom-in ms-2"></i>
                </button>
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      crop.zoom(-0.1);
                    }
                  }}
                >
                  Zoom out<i class="bi bi-zoom-out ms-2"></i>
                </button>
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      const contData = crop.getContainerData();
                      crop.setCanvasData({
                        width: contData.width,
                        top: 0,
                        left: 0,
                      });
                    }
                  }}
                >
                  Width<i class="bi bi-arrow-left-right ms-2"></i>
                </button>
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      const contData = crop.getContainerData();
                      crop.setCanvasData({
                        height: contData.height,
                        top: 0,
                        left: 0,
                      });
                    }
                  }}
                >
                  Height<i class="bi bi-arrow-down-up ms-2"></i>
                </button>
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      crop.rotate(-45);
                    }
                  }}
                >
                  Rotate
                  <i class="bi bi-arrow-counterclockwise ms-2"></i>
                </button>
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      crop.rotate(45);
                    }
                  }}
                >
                  Rotate<i class="bi bi-arrow-clockwise ms-2"></i>
                </button>
                <button
                  class="btn bg-gr me-2 mb-2"
                  onClick={() => {
                    if (crop) {
                      crop.reset();
                    }
                  }}
                >
                  Undo<i class="bi bi-arrow-repeat ms-2"></i>
                </button>
              </div>
              <div class="d-flex">
                <button
                  class={`btn me-3 ${
                    loadingFetch ? "pe-none btn-secondary" : "btn-primary"
                  }`}
                  download="cropped.jpg"
                  onClick={(e) => {
                    if (crop) {
                      const data = crop
                        .getCroppedCanvas({
                          fillColor: "#fff",
                        })
                        .toDataURL("image/jpeg");
                      // e.target.href =
                      setLoadingFetch(true);
                      api
                        .updMedia({ data, name: props.name, id: props.id })
                        .then((result) => {
                          if (result.ok !== false) {
                            result.url =
                              result.url + "?v=" + new Date().getTime();
                            save(result, props.i);
                            close();
                          }
                        })
                        .catch((err) => {
                          console.log(`err`, err);
                        });
                    }
                  }}
                >
                  {loadingFetch ? "Loading" : "Save"}
                </button>
                <button class="btn btn-outline-dark" onClick={close}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupEditMedia;
