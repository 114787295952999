import React, { useState, useEffect, forwardRef, useRef } from "react";
import api from "./func/api";
import { IMAGE_URL, colorsStatus, SERVER_URL } from "./func/constants";
import Pagination from "./modules/Pagination";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function addOneDay(date) {
  var result = new Date(date);
  result.setDate(result.getDate() + 1);
  return result;
}

const sortFields = [
  { type: "по тексту", field: "dannyeZapolneniya.textLength", value: 0 },
  { type: "по фото", field: "dannyeZapolneniya.photoLength", value: 0 },
];

const CustomInputDateBtn = forwardRef(({ onClick }, ref) => {
  return (
    <button
      className="btn btn-sm btn-outline-dark d-flex align-items-center me-3 h-100"
      ref={ref}
      onClick={onClick}
    >
      Дата<i className="bi bi-sliders ms-2"></i>
    </button>
  );
});

const listStatusToChange = [
  "В очереди",
  "В работе",
  "Модерация",
  "Исправить",
  "На оплату",
  "Выполнено",
];

function Main({ setActiveProduct, routProps, userData, ispolnitel }) {
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandSearch, setBrandSearch] = useState("");
  const [brandsChoose, setBrandsChoose] = useState(
    window.localStorage.getItem("brandsChoose")
      ? JSON.parse(window.localStorage.getItem("brandsChoose"))
      : [],
  );

  const [ispolnitelChoose, setIspolnitelChoose] = useState(
    window.localStorage.getItem("ispolnitelChoose")
      ? JSON.parse(window.localStorage.getItem("ispolnitelChoose"))
      : [],
  );
  const [status, setStatus] = useState(
    window.localStorage.getItem("status")
      ? window.localStorage.getItem("status")
      : "all",
  );
  const [countProds, setCountProds] = useState(0);
  const [prodInPage, setProdInPage] = useState(
    window.localStorage.getItem("prodInPage")
      ? +window.localStorage.getItem("prodInPage")
      : 50,
  );
  const [page, setPage] = useState(
    routProps.location.search.includes("page=")
      ? routProps.location.search.split("page=")[1].split(";")[0]
      : 1,
  );

  const [dateSort, setDateSort] = useState(0);
  const [nameSort, setNameSort] = useState(0);
  const [sortContent, setSortContent] = useState({
    type: "по тексту",
    field: "dannyeZapolneniya.textLength",
    value: 0,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [userScore, setUserScore] = useState(0);
  const [chooseContentElems, setChooseContentElems] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [openChangeStatusPopup, setOpenChangeStatusPopup] = useState(false);
  const [chooseAllInPage, setChooseAllInPage] = useState(false);
  const [chooseAll, setChooseAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchTimerRef = useRef(null);

  const haveSort = brandsChoose.length || ispolnitelChoose.length || startDate;

  const sortFieldsHTML = sortFields.map((el, i) => (
    <li
      onClick={() => {
        if (el.type !== sortContent.type) setSortContent(el);
      }}
      key={i}
    >
      <div className="dropdown-item">
        <div className="form-check">
          <input
            className="form-check-input"
            id="flexCheckChecked1"
            type="radio"
            checked={sortContent.type === el.type}
            name="flexRadioDefault"
          ></input>
          <label className="form-check-label" for="flexCheckChecked1">
            {el.type}
          </label>
        </div>
      </div>
    </li>
  ));

  useEffect(() => {
    if (chooseAllInPage) {
      setChooseContentElems(data.map((prod) => prod._id));
    } else {
      setChooseContentElems([]);
    }
  }, [chooseAllInPage]);

  const getFilterData = () => {
    const filter = {};
    const sort = {};
    let statusFiltr = false;
    let ispolnitels = false;
    if (brandsChoose.length) filter.brend = brandsChoose;
    if (status !== "all") statusFiltr = status;

    if (ispolnitelChoose.length)
      ispolnitels = ispolnitelChoose.map((el) => el.id);
    if (dateSort !== 0) {
      sort["dannyeZapolneniya.updatedAt"] = dateSort;
      sort.updatedAt = dateSort;
    }
    if (nameSort !== 0) {
      sort.imya = nameSort;
    }
    if (sortContent.value !== 0) {
      sort[sortContent.field] = sortContent.value;
    }
    if (startDate || endDate) {
      filter["dannyeZapolneniya.updatedAt"] = {
        $gte: startDate,
        $lte: addOneDay(endDate),
      };
    }
    if (searchString)
      filter.$text = {
        $search: `${searchString}`,
      };

    return {
      pagin: {
        limit: prodInPage,
        skip: (page - 1) * prodInPage,
      },
      filter,
      status: statusFiltr,
      ispolnitels,
      sort,
    };
  };

  const getProducts = () => {
    const data = getFilterData();

    setData([]);
    setCountProds("-");
    setIsLoading(true);

    api
      .filterDatas({
        data,
      })
      .then(({ result, countProds }) => {
        setData(result);
        setCountProds(countProds);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProducts();
    setChooseAll(false);
    setChooseAllInPage(false);
    setChooseContentElems([]);
  }, [
    brandsChoose,
    status,
    ispolnitelChoose,
    prodInPage,
    page,
    dateSort,
    nameSort,
    sortContent,
    startDate,
    endDate,
    searchString,
  ]);

  useEffect(() => {
    const data = {
      aggregate: {
        uniqueValues: ["brend"],
      },
    };
    // let statusFiltr = false;
    // if (brandsChoose.length) filter.brend = brandsChoose;
    if (status !== "all") data.status = status;

    if (ispolnitelChoose.length)
      data.ispolnitels = ispolnitelChoose.map((el) => el.id);
    if (startDate || endDate) {
      data.filter = {};
      data.filter["dannyeZapolneniya.updatedAt"] = {
        $gte: startDate,
        $lte: addOneDay(endDate),
      };
    }
    api
      .filterDatas({
        data,
        aggr: true,
      })
      .then(({ result }) => {
        if (result) setBrands(result.sort());
        else setBrands([]);
        // if (result[0]?.count)
        //   setCountProds(result[0].count);
        // else setCountProds(0);
      })
      .catch((err) => {});
  }, [status, ispolnitelChoose, startDate, endDate]);

  useEffect(() => {
    if (userData)
      api
        .getScore({ id: userData._id })
        .then((result) => {
          setUserScore(result.sum);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
  }, [userData]);

  useEffect(() => {
    try {
      window.localStorage.setItem("status", status);
    } catch (error) {
      console.log("error :>> ", error);
    }

    setPage(1);
  }, [status]);

  useEffect(() => {
    try {
      window.localStorage.setItem("prodInPage", prodInPage);
    } catch (error) {
      console.log("error :>> ", error);
    }
  }, [prodInPage]);

  useEffect(() => {
    try {
      window.localStorage.setItem("brandsChoose", JSON.stringify(brandsChoose));
    } catch (error) {
      console.log("error :>> ", error);
    }
  }, [brandsChoose]);
  useEffect(() => {
    try {
      window.localStorage.setItem(
        "ispolnitelChoose",
        JSON.stringify(ispolnitelChoose),
      );
    } catch (error) {
      console.log("error :>> ", error);
    }
  }, [ispolnitelChoose]);

  const prodsHTML = data.map((prod) => {
    if (prod.kontent === null) prod.kontent = undefined;
    return (
      <tr key={prod._id}>
        {userData && userData.admin && status !== "all" ? (
          <td className="t-checkbox">
            <input
              className="form-check-input me-3"
              id="checkboxNoLabel"
              type="checkbox"
              checked={chooseContentElems.includes(prod._id)}
              aria-label="..."
              onChange={(e) => {
                if (!chooseContentElems.includes(prod._id)) {
                  chooseContentElems.push(prod._id);
                  setChooseContentElems([...chooseContentElems]);
                } else {
                  chooseContentElems.splice(
                    chooseContentElems.indexOf(prod._id),
                    1,
                  );
                  setChooseContentElems([...chooseContentElems]);
                }
              }}
            ></input>
          </td>
        ) : null}
        <td>
          <span style={{ fontWeight: "500" }}>
            {prod.kontent !== undefined
              ? moment(prod.kontent.updatedAt || prod.kontent.createdAt).format(
                  "DD.MM.YYYY",
                )
              : prod.updatedAt
              ? moment(prod.updatedAt || prod.createdAt).format("DD.MM.YYYY")
              : ""}
          </span>
        </td>
        <td>
          <div className="d-flex">
            <div className="square-img me-2" style={{ width: "80px" }}>
              <div className="square-img-wrp">
                {prod.kontent !== undefined && prod.kontent.foto.length > 0 && (
                  <img
                    src={
                      IMAGE_URL +
                      (prod.kontent
                        ? prod.kontent.foto[0].formats.small.url
                        : "")
                    }
                    alt="product-img"
                  />
                )}
                {prod.kontent === undefined &&
                  prod.izobrazheniya &&
                  prod.izobrazheniya.length > 0 && (
                    <img
                      src={
                        IMAGE_URL +
                        (prod.izobrazheniya
                          ? prod.izobrazheniya[0].formats.small.url
                          : "")
                      }
                      alt="product-img"
                    />
                  )}
              </div>
            </div>
            <div className="card-product-info w-100">
              <a
                className="w-100 d-flex justify-content-between"
                // onClick={() => {
                //   setActiveProduct(prod);
                //   routProps.history.push("/" + prod._id);
                // }}
                href={"/" + prod._id}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <span
                  className="d-block"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "304px",
                    fontWeight: 500,
                  }}
                >
                  {prod.imya}
                </span>
                <i className="d-block bi bi-arrow-right"> </i>
              </a>
              <div className="d-flex">
                <p className="mb-1 me-2" style={{ fontWeight: "700" }}>
                  {prod.brend}
                </p>
                <p className="m-0">{prod.artikulProizvoditelya || ""}</p>
              </div>
              {/* <div className="d-flex align-items-center">
                <span className="badge bg-secondary me-2"> х1.5</span>
                <p className="m-0"> Баллы: 0 </p>
              </div> */}
            </div>
          </div>
        </td>
        <td>
          <div className="card-content-info">
            <div className="d-flex">
              <p className="m-0 mb-1">
                Фото:
                <span className="p-1 fw-bold">
                  {prod.kontent ? prod.kontent.foto?.length : 0}
                </span>
              </p>
            </div>
            <p>
              Текст:
              <span className="p-1 text-success fw-bold">
                {prod.kontent && prod.kontent.opisanie
                  ? prod.kontent.opisanie?.lengthText
                  : 0}
              </span>
              <span>/</span>
              <span className="p-1 text-danger fw-bold">1000 </span>
            </p>
          </div>
        </td>
        <td>
          <div className="text-end">
            {prod.kontent !== undefined && (
              <p className="m-0 mb-3">
                {prod.kontent.ispolnitel ? prod.kontent.ispolnitel.fio : ""}
              </p>
            )}
            <span
              className={`badge ${
                colorsStatus[prod.kontent ? prod.kontent.status : "В очереди"]
              }`}
              style={{
                fontSize: "15px",
                padding: "6px 8px",
                fontWeight: "bold",
              }}
            >
              {prod.kontent ? prod.kontent.status : "В очереди"}
            </span>
          </div>
        </td>
      </tr>
    );
  });

  const changeStatusLength = chooseAll ? countProds : chooseContentElems.length;
  return (
    <div className="wrapper flex-nowrap mt-5">
      <div className="container main-page__container">
        <div className="row align-items-baseline">
          <div className="col">
            <div className="d-flex ms-auto flex-shrink-0 mb-3">
              <div className="dropdown" style={{ position: "relative" }}>
                <button
                  className="btn btn-sm btn-outline-primary d-flex align-items-center me-3 h-100"
                  id="dropdownCatsChooseButton"
                  type="button"
                  data-bs-auto-close="outside"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Бренды<i className="bi bi-sliders ms-2"></i>
                </button>
                <ul
                  className="dropdown-menu main-page__dropdown mt-2 rounded"
                  aria-labelledby="dropdownCatsChooseButton"
                  style={{
                    width: "240px",
                    maxHeight: "600px",
                    overflowY: "auto",
                  }}
                >
                  <li className="px-2 mb-1">
                    <input
                      className="search form-control me-2"
                      type="search"
                      placeholder="Поиск"
                      aria-label="Search"
                      value={brandSearch}
                      onChange={(e) => setBrandSearch(e.target.value)}
                    ></input>
                  </li>
                  {brands.map((brand, i) => {
                    if (
                      brandSearch &&
                      !brand.toLowerCase().includes(brandSearch.toLowerCase())
                    )
                      return null;
                    else {
                      const inFilter = brandsChoose.indexOf(brand);
                      return (
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (inFilter !== -1) {
                              brandsChoose.splice(inFilter, 1);
                            } else {
                              brandsChoose.push(brand);
                            }
                            setBrandsChoose([...brandsChoose]);
                          }}
                        >
                          <div className="dropdown-item">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={inFilter !== -1}
                              ></input>
                              <label className="form-check-label">
                                {brand}
                              </label>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  })}
                  {/* <li
                    style={{ position: "absolute", left: "0px", bottom: "0px" }}
                  >
                    <div className="dropdown-item">
                      <div className="row">
                        <div className="col-6">
                          <button className="btn btn-sm btn-danger w-100">
                            Сбросить
                          </button>
                        </div>
                        <div className="col-6">
                          <button className="btn btn-sm btn-primary">
                            Применить
                          </button>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-sm btn-outline-primary d-flex align-items-center me-3 h-100"
                  id="dropdownCatsChooseButton"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  Исполнитель<i className="bi bi-sliders ms-2"></i>
                </button>
                <ul
                  className="dropdown-menu main-page__dropdown mt-2 rounded"
                  aria-labelledby="dropdownCatsChooseButton"
                  style={{ width: "240px" }}
                >
                  {ispolnitel.map((el, i) => {
                    let inFilter = -1;
                    ispolnitelChoose.some((isp, i) => {
                      if (isp.id === el._id) {
                        inFilter = i;
                        return true;
                      }
                      return false;
                    });
                    return (
                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (inFilter !== -1) {
                            ispolnitelChoose.splice(inFilter, 1);
                          } else {
                            ispolnitelChoose.push({ id: el._id, name: el.fio });
                          }
                          setIspolnitelChoose([...ispolnitelChoose]);
                        }}
                      >
                        <div className="dropdown-item">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={inFilter !== -1}
                            ></input>
                            <label className="form-check-label">{el.fio}</label>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <div className="dropdown">
                <button
                  className="btn btn-sm btn-outline-dark d-flex align-items-center me-3 h-100"
                  id="dropdownCatsChooseButton"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Дата<i className="bi bi-sliders ms-2"></i>
                </button>
                <div
                  className="dropdown-menu main-page__dropdown  rounded"
                  aria-labelledby="dropdownCatsChooseButton"
                  style={{ padding: "0px" }}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(dates) => {
                      const [start, end] = dates;

                      setStartDate(start);
                      setEndDate(end);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </div>
              </div> */}
              <DatePicker
                customInput={<CustomInputDateBtn />}
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;

                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
              />
              {haveSort && (
                <button
                  className="btn btn-sm btn-outline-danger d-flex align-items-center"
                  onClick={() => {
                    setBrandsChoose([]);
                    setIspolnitelChoose([]);
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  Сбросить всё
                </button>
              )}
            </div>
          </div>
          <div className="col-5">
            <form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                className="search form-control me-2"
                type="search"
                placeholder={
                  userData &&
                  userData.admin &&
                  userData.login === "nk@motorfirst.ru"
                    ? "Поиск для Царя (видишь только ты)"
                    : "Поиск по товарам"
                }
                aria-label="Search"
                defaultValue={searchString}
                onChange={(e) => {
                  const value = e.target.value;
                  if (searchTimerRef.current) {
                    clearTimeout(searchTimerRef.current);
                  }
                  searchTimerRef.current = setTimeout(() => {
                    setSearchString(value);
                  }, 500);
                }}
              ></input>
            </form>
          </div>
        </div>
        {brandsChoose.length > 0 && (
          <div className="d-flex align-items-center">
            <h6 className="m-0 me-3">Бренды:</h6>
            <ul
              className="d-flex p-0 m-0"
              style={{ listStyleType: "none" }}
              id="chooseSelect01"
            >
              {brandsChoose.map((el, i) => (
                <li className="chooseSelect p-1 mr-1">
                  <p className="m-0 me-1">
                    {el}
                    <i
                      className="bi bi-x-circle-fill mx-2 text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        brandsChoose.splice(i, 1);
                        setBrandsChoose([...brandsChoose]);
                      }}
                    ></i>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {ispolnitelChoose.length > 0 && (
          <div className="d-flex align-items-center mb-4">
            <h6 className="m-0 me-3">Исполнитель:</h6>
            <ul
              className="d-flex p-0 m-0"
              style={{ listStyleType: "none" }}
              id="chooseSelect01"
            >
              {ispolnitelChoose.map((el, i) => (
                <li className="chooseSelect p-1 mr-1">
                  <p className="m-0 me-1">
                    {el.name}
                    <i
                      className="bi bi-x-circle-fill mx-2 text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        ispolnitelChoose.splice(i, 1);
                        setIspolnitelChoose([...ispolnitelChoose]);
                      }}
                    ></i>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {startDate !== null && (
          <div className="d-flex align-items-center mb-4">
            <h6 className="m-0 me-3">Дата:</h6>
            <ul
              className="d-flex p-0 m-0"
              style={{ listStyleType: "none" }}
              id="chooseSelect01"
            >
              <li className="chooseSelect p-1 mr-1">
                <p className="m-0 me-1">
                  {moment(startDate).format("DD.MM.YYYY")}
                  {endDate !== null &&
                    ` - ${moment(endDate).format("DD.MM.YYYY")}`}
                  <i
                    className="bi bi-x-circle-fill mx-2 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                    }}
                  ></i>
                </p>
              </li>
            </ul>
          </div>
        )}
        <div className="d-flex mb-4 align-items-center">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a
                className={`nav-link ${status === "all" && "active"}`}
                onClick={() => status !== "all" && setStatus("all")}
              >
                Все
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${status === "В очереди" && "active"}`}
                onClick={() => status !== "В очереди" && setStatus("В очереди")}
              >
                В очереди
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${status === "В работе" && "active"}`}
                onClick={() => status !== "В работе" && setStatus("В работе")}
              >
                В работе
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${status === "Модерация" && "active"}`}
                onClick={() => status !== "Модерация" && setStatus("Модерация")}
              >
                Модерация{" "}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${status === "Исправить" && "active"}`}
                onClick={() => status !== "Исправить" && setStatus("Исправить")}
              >
                Исправить
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${status === "На оплату" && "active"}`}
                onClick={() => status !== "На оплату" && setStatus("На оплату")}
              >
                На оплату
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${status === "Выполнено" && "active"}`}
                onClick={() => status !== "Выполнено" && setStatus("Выполнено")}
              >
                Выполнено
              </a>
            </li>
          </ul>
          {userData && userData.admin && chooseContentElems.length > 0 && (
            <div className="d-grid d-md-block ms-auto">
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={() => {
                  setOpenChangeStatusPopup(true);
                }}
              >
                Смена статуса ({changeStatusLength})
              </button>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center mb-4">
          <span className="me-4">Всего товаров: {countProds}</span>
          <span className="me-4">Итого баллов: {userScore}</span>
          {chooseContentElems.length > 0 && (
            <span>
              Выбрано продуктов: {changeStatusLength}{" "}
              <button
                className="btn btn-sm btn-outline-secondary ms-2"
                onClick={() => {
                  if (chooseAll) {
                    setChooseAllInPage(false);
                    setChooseAll(false);
                  } else {
                    setChooseAllInPage(true);
                    setChooseAll(true);
                  }
                }}
              >
                {chooseAll ? "Отменить выбор" : "Выбрать все"}
              </button>{" "}
            </span>
          )}
          <div className="d-flex ms-auto">
            {userData.moderator && (
              <button
                className="btn btn-outline-dark d-flex align-items-center me-3"
                onClick={() => {
                  api
                    .getExport()
                    .then((result) => {
                      const aLink = document.createElement("a");
                      aLink.href = SERVER_URL + "/temps/" + result.name;
                      aLink.target = "_blank";
                      aLink.download = "export.xlsx";
                      aLink.click();
                    })
                    .catch((err) => {
                      console.log("err :>> ", err);
                    });
                }}
              >
                Скачать отчёт
                <i className="bi bi-download" style={{ marginLeft: "8px" }}></i>
              </button>
            )}
            <div className="input-group" style={{ width: "153px" }}>
              <span className="input-group-text" id="basic-addon1">
                Выводить
              </span>
              <input
                className="form-control"
                type="text"
                value={prodInPage}
                aria-label="Выводить"
                aria-describedby="basic-addon1"
                onChange={(e) => setProdInPage(+e.currentTarget.value)}
              ></input>
            </div>
          </div>
        </div>
        <table className="table table-defult table-striped table-hover mb-0 rounded table-border">
          <thead className="text-secondary">
            <tr>
              {userData && userData.admin && status !== "all" ? (
                <th className="t-checkbox" style={{ width: "2%" }}>
                  <input
                    className="form-check-input"
                    id="checkboxNoLabel"
                    type="checkbox"
                    checked={chooseAllInPage}
                    onChange={() => setChooseAllInPage((choose) => !choose)}
                  ></input>
                </th>
              ) : null}
              <th style={{ width: "10%", cursor: "pointer" }}>
                Дата
                <i
                  className={`bi ${
                    dateSort === 0
                      ? "bi-arrow-down-up"
                      : dateSort === -1
                      ? "bi-arrow-down"
                      : "bi-arrow-up"
                  } ms-2`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (dateSort === 0) {
                      setDateSort(-1);
                    } else if (dateSort === -1) {
                      setDateSort(1);
                    } else {
                      setDateSort(0);
                    }
                  }}
                ></i>
              </th>
              <th style={{ width: "35%" }}>
                Продукт
                <i
                  className={`bi ${
                    nameSort === 0
                      ? "bi-arrow-down-up"
                      : nameSort === -1
                      ? "bi-arrow-down"
                      : "bi-arrow-up"
                  } ms-2`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (nameSort === 0) {
                      setNameSort(-1);
                    } else if (nameSort === -1) {
                      setNameSort(1);
                    } else {
                      setNameSort(0);
                    }
                  }}
                ></i>
              </th>
              <th style={{ width: "20%" }}>
                <div className="d-flex">
                  <p className="m-0">Контент:</p>
                  <div className="dropdown">
                    <span
                      className="text-primary ms-2"
                      id="dropdownChooseButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ cursor: "pointer" }}
                    >
                      {sortContent.type}
                    </span>
                    <i
                      className={`bi ${
                        sortContent.value === 0
                          ? "bi-arrow-down-up"
                          : sortContent.value === -1
                          ? "bi-arrow-down"
                          : "bi-arrow-up"
                      } ms-2`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (sortContent.value === 0) {
                          setSortContent({ ...sortContent, value: -1 });
                        } else if (sortContent.value === -1) {
                          setSortContent({ ...sortContent, value: 1 });
                        } else {
                          setSortContent({ ...sortContent, value: 0 });
                        }
                      }}
                    ></i>
                    <ul
                      className="dropdown-menu main-page__dropdown mt-2 rounded"
                      aria-labelledby="dropdownChooseButton"
                      style={{ width: "137px" }}
                    >
                      {sortFieldsHTML}
                    </ul>
                  </div>
                </div>
              </th>
              <th style={{ width: "30%" }}>
                <p className="m-0 float-end">Выполнение </p>
              </th>
            </tr>
          </thead>

          <tbody>{prodsHTML}</tbody>
        </table>
        {isLoading ? (
          <div className="loader">
            <span />
          </div>
        ) : null}

        <Pagination
          page={page}
          setPage={setPage}
          route={routProps}
          prodInPage={prodInPage}
          setProdInPage={setProdInPage}
          count={countProds}
        />
      </div>
      {userData && userData.admin ? (
        <div
          className={`modal fade ${openChangeStatusPopup ? "show" : ""}`}
          tabindex="-1"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Массовая замена статуса</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    setOpenChangeStatusPopup(false);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Заменить у выбранных товаров ({changeStatusLength}) статус с{" "}
                  <strong>"{status}"</strong> на
                </p>{" "}
                <select
                  className="form-select"
                  onChange={(e) => {
                    setNewStatus(e.currentTarget.value);
                  }}
                >
                  {[
                    <option value="">Выбор нового статуса...</option>,
                    ...listStatusToChange.map((newStatus) => {
                      if (newStatus !== status) {
                        return <option value={newStatus}>{newStatus}</option>;
                      }
                      return null;
                    }),
                  ]}
                </select>
                {(status === "В работе" || status === "Модерация") &&
                  (newStatus === "На оплату" || newStatus === "Выполнено") && (
                    <p className="text-danger mt-4">
                      Внимание!
                      <br /> При выполнении операции, контент у выбранных
                      товаров будет автоматически подвержден и загружен на сайт!
                    </p>
                  )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setOpenChangeStatusPopup(false);
                  }}
                >
                  Отменить
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.persist();
                    e.currentTarget.disabled = true;
                    e.currentTarget.textContent = "Выполняется...";
                    const data = {
                      idToChange: chooseContentElems,
                      newStatus,
                      oldStatus: status,
                      chooseAll,
                    };
                    if (chooseAll) {
                      data.filter = getFilterData();
                      delete data.filter.pagin;
                      delete data.filter.sort;
                    }

                    function resetBtn(succes = true) {
                      if (succes) {
                        e.target.textContent = "Применить";
                        e.target.classList.remove("btn-danger");
                        e.target.disabled = false;
                      } else {
                        e.target.textContent = "Ошибка";
                        e.target.classList.add("btn-danger");
                        e.target.disabled = false;
                        setTimeout(() => {
                          resetBtn(true);
                        }, 3000);
                      }
                    }

                    api
                      .postChangeStatusArr(data)
                      .then((result) => {
                        resetBtn(true);
                        setOpenChangeStatusPopup(false);
                        setNewStatus("");
                        getProducts();
                      })
                      .catch((err) => {
                        resetBtn(false);
                        console.log("err", err);
                      });
                    // .finally(() => {
                    //   setOpenChangeStatusPopup(false);
                    // });
                  }}
                >
                  Применить
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Main;
