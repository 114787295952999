import React, { useEffect, useState } from "react";

const Pagination = ({
  page,
  setPage,
  route,
  prodInPage,
  setProdInPage,
  count,
}) => {
  const changePage = (page) => {
    setPage(page);
    route.history.push({ search: "?page=" + page });
    window.scrollTo(0, 0);
  };

  const pageHTML = [];

  const canPage = Math.ceil(count / prodInPage);

  if (page == 1) {
    pageHTML.push(
      <ul className="pagination m-0">
        <li className="page-item active">
          <a className="page-link" href="#">
            1
          </a>
        </li>
        {canPage >= 2 ? (
          <li
            className="page-item "
            onClick={(e) => {
              e.preventDefault();
              changePage(2);
            }}
          >
            <a className="page-link" href="#">
              2
            </a>
          </li>
        ) : null}
        {canPage >= 3 ? (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                changePage(3);
              }}
            >
              3
            </a>
          </li>
        ) : null}
        {canPage > 3 ? (
          <li
            className="page-item"
            onClick={(e) => {
              e.preventDefault();
              changePage(+page + 1);
            }}
          >
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        ) : null}
      </ul>,
    );
  } else {
    pageHTML.push(
      <ul className="pagination m-0">
        <li
          className="page-item"
          onClick={(e) => {
            e.preventDefault();
            changePage(+page - 1);
          }}
        >
          <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          className="page-item"
          onClick={(e) => {
            e.preventDefault();
            changePage(+page - 1);
          }}
        >
          <a className="page-link" href="#">
            {+page - 1}
          </a>
        </li>

        <li className="page-item active">
          <a className="page-link" href="#">
            {page}
          </a>
        </li>

        {canPage > page ? (
          <li
            className="page-item"
            onClick={(e) => {
              e.preventDefault();
              changePage(+page + 1);
            }}
          >
            <a className="page-link" href="#">
              {+page + 1}
            </a>
          </li>
        ) : null}
        {canPage > page + 1 ? (
          <li
            className="page-item"
            onClick={(e) => {
              e.preventDefault();
              changePage(+page + 1);
            }}
          >
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        ) : null}
      </ul>,
    );
  }

  return (
    <div className="d-flex mt-5 pe-0 justify-content-between align-items-center">
      <div>
        <nav aria-label="Page navigation example">{pageHTML}</nav>
      </div>
      <div className="d-flex">
        <div className="input-group" style={{ width: "153px" }}>
          <span className="input-group-text" id="basic-addon1">
            Выводить
          </span>
          <input
            className="form-control"
            type="text"
            value={prodInPage}
            aria-label="Выводить"
            aria-describedby="basic-addon1"
            onChange={(e) => setProdInPage(+e.currentTarget.value)}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
