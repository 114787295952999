import React, { useRef, useState, useEffect } from "react";
// import { SERVER_URL } from "../../func/constants";
import $ from "jquery";
// import { copyToBuffer } from "../../func/common";
// import api from "../../func/api";
import { ReactSortable } from "react-sortablejs";

const Characteristic = ({ data, setData }) => {
  const [required, setRequired] = useState([]);

  useEffect(() => {
    const required = [];
    data.kontent.harakteristiki.forEach((har) => {
      if (har.znachenie === "required") required.push(har.nazvanie);
    });

    setRequired(required);
  }, []);

  const charactHTML = data.kontent.harakteristiki.map((el, i) => {
    return (
      <div class="input-group mb-2" key={i}>
        <input
          class="form-control me-2 rounded"
          type="text"
          value={el.nazvanie}
          disabled={required.includes(el.nazvanie)}
          onChange={(e) => {
            data.kontent.harakteristiki[i].nazvanie = e.target.value;
            setData({ ...data });
          }}
        />
        <input
          class="form-control me-2 rounded"
          type="text"
          value={el.znachenie === "required" ? "" : el.znachenie}
          onChange={(e) => {
            data.kontent.harakteristiki[i].znachenie = e.target.value;
            setData({ ...data });
          }}
        />
        <div class="d-flex align-items-center">
          <i
            class="bi bi-trash me-4"
            onClick={() => {
              data.kontent.harakteristiki.splice(i, 1);
              setData({ ...data });
            }}
          ></i>
          <i
            class="bi bi-files-alt me-4"
            onClick={() => {
              data.kontent.harakteristiki.splice(i, 0, el);
              setData({ ...data });
            }}
          ></i>
          <span class="m-0 me-4 text-muted">{i + 1}</span>
          <i class="bi bi-list"></i>
        </div>
      </div>
    );
  });

  return (
    <ReactSortable
      animation={200}
      list={data.kontent.harakteristiki}
      setList={(newOrderArr) => {
        data.kontent.harakteristiki = [...newOrderArr];
        setData({ ...data });
      }}
      swapThreshold={0.65}
      className="chars-sortable"
      handle=".bi-list"
    >
      {charactHTML}
    </ReactSortable>
  );
};

export default Characteristic;
