import React, { useEffect, useState, useRef } from "react";
import ManyMedia from "./modules/ManyMedia";
import Characteristic from "./modules/Characteristic";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import TextBlock from "./modules/TextBlock";
import api from "./func/api";
import moment from "moment";
import DOMPurify from "dompurify";
import { colorsStatus } from "./func/constants";
import edjsHTML from "editorjs-html";
import dayjs from "dayjs";

const setDataLocal = (data) => {
  window.localStorage.setItem(
    "localData",
    JSON.stringify({ ...data, dateSave: new Date() }),
  );
};
const getDataLocal = () => {
  return JSON.parse(window.localStorage.getItem("localData"));
};

function Lk({ slug, activeProduct, userData, ispolnitel }) {
  const [data, setData] = useState(null);
  const [isModeration, setIsModeration] = useState(null);
  const [chatMessage, setChatMessage] = useState("");
  const [deactiveFields, setDeactiveFields] = useState(false);

  const checkVersion = (data) => {
    const localData = getDataLocal();
    if (
      data.kontent &&
      data.kontent.moderaciya === undefined &&
      data.kontent.status === "Модерация"
    ) {
      const newState = {
        opisanie: false,
        foto: false,
        harakteristiki: false,
      };
      data.kontent.moderaciya = newState;
    }

    if (data.kontent && data.kontent.koef === undefined) {
      data.kontent.koef = 1;
    }

    if (
      data.kontent &&
      localData &&
      localData._id === data._id &&
      localData.dateSave > data.kontent.updatedAt
    ) {
      const local = window.confirm(
        `Загрузить локальную версию?
        Дата изменения локальной версии ${moment(localData.dateSave).format(
          "DD.MM.YYYY HH:mm:ss",
        )}, версии с сервера - ${moment(data.kontent.updatedAt).format(
          "DD.MM.YYYY HH:mm:ss",
        )}`,
      );
      if (local) {
        delete localData.dateSave;
        return localData;
      }
    }
    return data;
  };

  const setDataWithLocal = (data) => {
    setDataLocal(data);
    setData(data);
  };
  const getDataServer = () => {
    api
      .getProd(slug)
      .then((result) => {
        if (result[0].kontent === null) result[0].kontent = undefined;
        if (result[0] && result[0].kontent !== undefined) {
          if (
            (result[0].kontent.foto === undefined ||
              result[0].kontent.foto?.length === 0) &&
            result[0].izobrazheniya &&
            result[0].izobrazheniya.length
          ) {
            result[0].kontent.foto = result[0].izobrazheniya;
          }
          if (
            (result[0].kontent.harakteristiki === undefined ||
              result[0].kontent.harakteristiki?.length === 0 ||
              (result[0]?.kontent?.updatedAt &&
                dayjs(result[0]?.kontent?.updatedAt).isBefore(
                  dayjs("07.09.22 12:00", "DD.MM.YY hh:mm"),
                ))) &&
            result[0].harakteristiki &&
            result[0].harakteristiki.length
          ) {
            result[0].kontent.harakteristiki = result[0].harakteristiki;
          }
        }
        setData(checkVersion(result[0]));
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };
  useEffect(() => {
    if (activeProduct) setData(checkVersion(activeProduct));
    else getDataServer();
  }, [slug]);

  useEffect(() => {
    if (
      data !== null &&
      data.kontent !== undefined &&
      data.kontent.status === "Модерация" &&
      userData.moderator &&
      isModeration === null
    ) {
      setIsModeration(true);
    } else if (
      data !== null &&
      data.kontent !== undefined &&
      data.kontent.status !== "Модерация"
    ) {
      setIsModeration(null);
    }
  }, [userData, data]);

  useEffect(() => {
    setDeactiveFields(
      (data && data.kontent === undefined) ||
        (!userData.moderator &&
          isModeration === null &&
          data &&
          (data.kontent === undefined ||
            (data.kontent &&
              (data.kontent.status === "Выполнено" ||
                data.kontent.status === "На оплату" ||
                data.kontent.ispolnitel._id !== userData._id)))),
    );
  }, [userData, data, isModeration]);

  const messagesHTML =
    data !== null && data.kontent !== undefined && data.kontent.chat
      ? data.kontent.chat.messages.map((el, i) => (
          <div className="mb-4 chat-me" key={i}>
            <p className="m-0 mb-2 lh-1 message">{el.message}</p>
            <p className="text-muted m-0 fs-12">{el.fio}</p>
            <p className="text-muted m-0 fs-12">
              {moment(el.date).format("DD.MM.YYYY HH:mm")}
            </p>
          </div>
        ))
      : [];

  let lastEditor = null;
  if (
    data &&
    data.dannyeZapolneniya &&
    data.dannyeZapolneniya.userID !== undefined
  ) {
    ispolnitel.some((el) => {
      if (data.dannyeZapolneniya.userID === el._id) {
        lastEditor = el.fio;
        return true;
      }
      return false;
    });
  }

  if (
    data &&
    data.kontent !== undefined &&
    (data.kontent.harakteristiki === undefined ||
      (data.kontent.harakteristiki !== undefined &&
        data.kontent.harakteristiki.length === 0))
  ) {
    data.kontent.harakteristiki = data.harakteristiki.map(
      ({ nazvanie, znachenie }) => ({ nazvanie, znachenie }),
    );
  }

  if (
    data &&
    data.kontent &&
    data.opisanie &&
    (data.kontent.opisanie === undefined ||
      (data.kontent.opisanie && !data.kontent.opisanie.json))
  ) {
    data.kontent.opisanie = { ...data.opisanie };
    if (!data.opisanie?.html)
      data.kontent.opisanie.html = edjsHTML().parse(data.opisanie.json);
  }

  if (data && data.opisanie && !data.opisanie.html) {
    data.opisanie.html = edjsHTML().parse(data.opisanie.json);
  }

  return (
    data !== null &&
    userData !== null && (
      <div className="wrapper flex-nowrap">
        <div className="container-fluid container-nav__catalog">
          <div className="row">
            <nav
              className="navbar navbar-dark p-0"
              style={{ backgroundColor: "#343A40" }}
            >
              <div className="d-flex align-items-center justify-content-between catalog__container w-100">
                <div className="navbar-brand d-flex align-items-center">
                  <i className="bi bi-link-45deg fs-14 lh-lg fw-bold ms-3 me-2"></i>
                  <span className="fs-14 lh-lg fw-bold">{data.itemdesc}</span>
                </div>
                <p
                  className="fs-14 lh-lg navbar-brand d-block m-0 me-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    api
                      .logout()
                      .then((result) => {
                        window.location.pathname = "/";
                      })
                      .catch((err) => {
                        console.log(`err`, err);
                      });
                  }}
                >
                  Выход{" "}
                </p>
              </div>
            </nav>
          </div>
        </div>
        <div
          className="container container-nav__catalog"
          style={{ paddingRight: "0", paddingLeft: "0" }}
        >
          <div className="main-page__container" style={{ paddingTop: "60px" }}>
            <nav>
              <div
                className="nav nav-tabs ms-3"
                id="nav-tab"
                role="tablist"
                style={{ borderBottom: "0" }}
              >
                {isModeration !== null && (
                  <a
                    className={`nav-link ${isModeration ? "active" : ""}`}
                    id="contentBlockBtn"
                    onClick={() => {
                      setIsModeration(true);
                    }}
                  >
                    Модерация{" "}
                  </a>
                )}
                <a
                  className={`nav-link ${!isModeration ? "active" : ""}`}
                  id="contentBlockBtn"
                  onClick={() => {
                    setIsModeration(false);
                  }}
                >
                  Контент{" "}
                </a>
              </div>
              <div className="row position-relative">
                <div className="col-9">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="contentBlock"
                      role="tabpanel"
                      aria-labelledby="contentBlockBtn"
                    >
                      <div className="card">
                        {data.kontent ||
                        data.izobrazheniya ||
                        data.harakteristiki ? (
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-1">
                              <h6 className="m-0 me-3">Имя:</h6>
                              <p className="m-0">{data.imya}</p>
                            </div>
                            <div className="d-flex align-items-center">
                              <h6 className="m-0 me-3">Артикул:</h6>
                              <p className="m-0">
                                {data.razmery[0].partnumber}
                              </p>
                            </div>
                            <div className="border-bottom my-4"></div>
                            {data.kontent !== undefined && userData.foto && (
                              <ManyMedia
                                name="Галерея"
                                pics={data.kontent.foto}
                                onChangeArray={() => {}}
                                data={data}
                                setData={setDataWithLocal}
                                isModeration={isModeration}
                                deactiveFields={deactiveFields}
                              />
                            )}
                            {data.kontent === undefined &&
                              userData.foto &&
                              data.izobrazheniya !== undefined &&
                              data.izobrazheniya.length && (
                                <ManyMedia
                                  name="Галерея"
                                  pics={data.izobrazheniya}
                                  onChangeArray={() => {}}
                                  data={data}
                                  setData={setDataWithLocal}
                                  isModeration={isModeration}
                                  deactiveFields={true}
                                />
                              )}

                            {/* <button className="btn btn-primary w-100 d-flex justify-content-between mt-2 mb-4">
                          <span>Создать</span>
                          <i className="bi bi-plus"></i>
                          </button> */}
                            {userData.opisanie && (
                              <>
                                <div className="border-bottom my-3"></div>
                                {isModeration &&
                                data.kontent !== undefined &&
                                !data.kontent.opisanie ? null : (
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-3">Описание</h6>
                                    {isModeration && (
                                      <div class="form-check form-switch">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id="flexSwitchCheckChecked"
                                          checked={
                                            data.kontent.moderaciya !==
                                              undefined &&
                                            data.kontent.moderaciya
                                              ?.opisanie === true
                                          }
                                          onChange={(e) => {
                                            if (
                                              data.kontent.moderaciya ===
                                              undefined
                                            )
                                              data.kontent.moderaciya = {};
                                            data.kontent.moderaciya.opisanie =
                                              e.currentTarget.checked;
                                            setDataWithLocal({ ...data });
                                          }}
                                        ></input>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {isModeration || deactiveFields ? (
                                  data.kontent &&
                                  data.kontent.opisanie !== undefined ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          data.kontent.opisanie.html.join(""),
                                        ),
                                      }}
                                    ></div>
                                  ) : (
                                    data.opisanie !== undefined &&
                                    data.opisanie !== null &&
                                    data.opisanie.html !== undefined && (
                                      <div
                                        style={{
                                          whiteSpace: "pre-line",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            data.opisanie.html.join(""),
                                          ),
                                        }}
                                      ></div>
                                    )
                                  )
                                ) : (
                                  <TextBlock
                                    data={data}
                                    setData={setDataWithLocal}
                                  />
                                )}
                              </>
                            )}
                            {userData.harakteristiki && (
                              <>
                                <div className="border-bottom my-3"></div>
                                <div className="d-flex justify-content-between">
                                  <h6 className="mb-3">
                                    Характеристики и особенности
                                  </h6>
                                  {isModeration && (
                                    <div class="form-check form-switch">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={
                                          data.kontent.moderaciya !==
                                            undefined &&
                                          data.kontent.moderaciya
                                            ?.harakteristiki === true
                                        }
                                        onChange={(e) => {
                                          if (
                                            data.kontent.moderaciya ===
                                            undefined
                                          )
                                            data.kontent.moderaciya = {};
                                          data.kontent.moderaciya.harakteristiki =
                                            e.currentTarget.checked;
                                          setDataWithLocal({ ...data });
                                        }}
                                      ></input>
                                    </div>
                                  )}
                                </div>

                                {(!isModeration || deactiveFields) && (
                                  <div className="input-group mb-2">
                                    <label className="form-control me-2 border-0">
                                      Тип
                                    </label>
                                    <label className="form-control me-2 border-0">
                                      Значение
                                    </label>
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        opacity: 0,
                                        pointerEvents: "none",
                                      }}
                                    >
                                      <i className="bi bi-trash me-4"></i>
                                      <i className="bi bi-files-alt me-4"></i>
                                      <span className="m-0 me-4 text-muted">
                                        1
                                      </span>
                                      <i className="bi bi-list"></i>
                                    </div>
                                  </div>
                                )}
                                {data.kontent === undefined &&
                                  data.harakteristiki !== undefined &&
                                  data.harakteristiki.length && (
                                    <ul>
                                      {data.harakteristiki.map((el) => (
                                        <li>
                                          {el.nazvanie} -{" "}
                                          {el.znachenie === "required"
                                            ? "Обязательно к заполнению"
                                            : el.znachenie}
                                        </li>
                                      ))}
                                    </ul>
                                  )}

                                {data.kontent !== undefined &&
                                  (isModeration || deactiveFields ? (
                                    <ul>
                                      {data.kontent.harakteristiki.map((el) => (
                                        <li>
                                          {el.nazvanie} - {el.znachenie}
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <Characteristic
                                      data={data}
                                      setData={setDataWithLocal}
                                    />
                                  ))}

                                {data.kontent !== undefined &&
                                  !isModeration && (
                                    <button
                                      className="btn btn-primary w-100 d-flex justify-content-between mb-4"
                                      onClick={() => {
                                        data.kontent.harakteristiki.push({
                                          nazvanie: "",
                                          znachenie: "",
                                        });

                                        setDataWithLocal({ ...data });
                                      }}
                                    >
                                      <span>Создать</span>
                                      <i className="bi bi-plus"></i>
                                    </button>
                                  )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-1">
                              <h6 className="m-0 me-3">Имя:</h6>
                              <p className="m-0">{data.imya}</p>
                            </div>
                            <div className="d-flex align-items-center mb-4">
                              <h6 className="m-0 me-3">Артикул:</h6>
                              <p className="m-0">
                                {data.razmery[0].partnumber}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card mb-4">
                    <div className="card-body">
                      {" "}
                      <span
                        className={`badge fs-16 mb-1 ${
                          colorsStatus[
                            data && data.kontent
                              ? data.kontent.status
                              : "В очереди"
                          ]
                        }`}
                        style={{
                          fontSize: "15px",
                          padding: "6px 8px",
                          fontWeight: "bold",
                        }}
                      >
                        {data && data.kontent
                          ? data.kontent.status
                          : "В очереди"}
                      </span>
                      {data && data.kontent !== undefined && (
                        <p className="m-0 mb-3 fs-16">
                          {data.kontent.ispolnitel
                            ? data.kontent.ispolnitel.fio
                            : ""}
                        </p>
                      )}
                      <div className="border-bottom"></div>
                      <div className="d-flex fw-bold fs-14 mt-3 mb-2">
                        <p className="m-0">
                          Описание:
                          <span className="text-success ms-1">
                            {data.kontent && data.kontent.opisanie
                              ? data.kontent.opisanie.lengthText
                              : 0}
                          </span>
                          <span>/1000</span>
                        </p>
                      </div>
                      <div className="d-flex fw-bold fs-14 mb-3">
                        <p className="m-0 me-2">
                          Фото:
                          <span className="text-danger ms-1">
                            {data.kontent ? data.kontent.foto.length : 0}
                          </span>
                          <span>/3</span>
                        </p>
                      </div>
                      {isModeration && (
                        <div className="form-check form-switch mb-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="ApproveAll"
                            checked={
                              data.kontent.moderaciya !== undefined &&
                              Object.keys(data.kontent.moderaciya).every(
                                (name) => data.kontent.moderaciya[name],
                              )
                            }
                            onChange={(e) => {
                              const bool = e.currentTarget.checked;
                              const newState = {
                                opisanie: bool,
                                foto: bool,
                                harakteristiki: bool,
                              };
                              data.kontent.moderaciya = newState;

                              setDataWithLocal({ ...data });
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <label className="form-check-label" for="ApproveAll">
                            Одобрить всё
                          </label>
                        </div>
                      )}
                      {isModeration !== null && (
                        <>
                          <div className="border-bottom"></div>
                          <div className="d-grid gap-2 mb-2 mt-3">
                            <button
                              className="btn btn-block btn-primary"
                              onClick={(e) => {
                                e.persist();
                                e.currentTarget.disabled = true;
                                if (data.kontent.status === "Модерация")
                                  data.kontent.status = "На оплату";
                                else if (data.kontent.status === "На оплату")
                                  data.kontent.status = "Выполнено";

                                api
                                  .postData(data)
                                  .then((result) => {
                                    getDataServer();
                                  })
                                  .catch((err) => {
                                    console.log(`err`, err);
                                  })
                                  .finally(() => {
                                    e.target.disabled = false;
                                  });
                              }}
                            >
                              <span className="f-14">
                                {data.kontent.status === "Модерация"
                                  ? "Разместить на сайте"
                                  : "Оплачен"}
                              </span>
                            </button>
                            {data.kontent.status === "Модерация" && (
                              <button
                                className="btn btn-block btn-danger"
                                onClick={(e) => {
                                  e.persist();
                                  e.currentTarget.disabled = true;
                                  data.kontent.status = "Исправить";

                                  api
                                    .postData(data)
                                    .then((result) => {
                                      getDataServer();
                                    })
                                    .catch((err) => {
                                      console.log(`err`, err);
                                    })
                                    .finally(() => {
                                      e.target.disabled = false;
                                    });
                                }}
                              >
                                <span className="f-14">Исправить</span>
                              </button>
                            )}
                          </div>
                          <div className="d-flex">
                            <div className="input-group">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Коэффициент
                              </span>
                              <input
                                className="form-control text-center"
                                type="text"
                                value={data.kontent.koef}
                                aria-label="Выводить"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                  data.kontent.koef = +e.currentTarget.value;
                                  setDataWithLocal({ ...data });
                                }}
                              />
                            </div>
                          </div>
                          <p className="m-0 mt-2 mb-3 fs-14 lh-1">
                            (0.5 – штраф 50%, 1.5 – премия 50% )
                          </p>
                        </>
                      )}
                      <div className="border-bottom"></div>
                      <div className="d-grid gap-2 mb-3 mt-3">
                        {isModeration !== null ||
                        (!userData.moderator &&
                          data.kontent &&
                          (data.kontent.status === "Модерация" ||
                            data.kontent.status === "На оплату" ||
                            data.kontent.status === "Выполнено")) ? null : (
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.persist();
                              e.currentTarget.disabled = true;
                              if (data.kontent) {
                                switch (data.kontent.status) {
                                  case "В очереди":
                                    data.kontent.status = "В работе";
                                    break;
                                  case "В работе":
                                  case "Исправить":
                                    data.kontent.status = "Модерация";
                                    break;

                                  case "На оплату":
                                    data.kontent.status = "Выполнено";
                                    break;

                                  default:
                                    break;
                                }
                              }
                              api
                                .postData(data)
                                .then((result) => {
                                  getDataServer();
                                })
                                .catch((err) => {
                                  console.log(`err`, err);
                                })
                                .finally(() => {
                                  e.target.disabled = false;
                                });
                            }}
                          >
                            <span className="fs-14">
                              {data.kontent === undefined ||
                              data.kontent?.status === "В очереди"
                                ? "Взять в работу"
                                : data.kontent.status === "В работе" ||
                                  data.kontent.status === "Исправить"
                                ? "На модерацию"
                                : data.kontent.status === "На оплату"
                                ? "Оплачен"
                                : "Разместить на сайте"}
                            </span>
                          </button>
                        )}
                        {userData.moderator &&
                          data.kontent &&
                          data.kontent.status === "В работе" && (
                            <button
                              className="btn btn-success"
                              onClick={(e) => {
                                e.persist();
                                e.currentTarget.disabled = true;
                                data.kontent.status = "На оплату";
                                const newState = {
                                  opisanie: true,
                                  foto: true,
                                  harakteristiki: true,
                                };
                                data.kontent.moderaciya = newState;

                                api
                                  .postData(data)
                                  .then((result) => {
                                    getDataServer();
                                  })
                                  .catch((err) => {
                                    console.log(`err`, err);
                                  })
                                  .finally(() => {
                                    e.target.disabled = false;
                                  });
                              }}
                            >
                              <span className="fs-14">Разместить на сайте</span>
                            </button>
                          )}
                        {userData.moderator &&
                          data.kontent !== undefined &&
                          data.kontent.status !== "В очереди" && (
                            <button
                              className="btn btn-block btn-danger"
                              onClick={(e) => {
                                e.persist();
                                e.currentTarget.disabled = true;

                                const verified = window.confirm(
                                  "Вы уверены, что хотите вернуть товар в очередь?",
                                );

                                if (verified)
                                  api
                                    .returnStatusToOrder({
                                      kontentId: data.kontent._id,
                                      tovarId: data._id,
                                    })
                                    .then((result) => {
                                      getDataServer();
                                    })
                                    .catch((err) => {
                                      console.log(`err`, err);
                                    })
                                    .finally(() => {
                                      e.target.disabled = false;
                                    });
                              }}
                            >
                              <span className="f-14">Вернуть в очередь</span>
                            </button>
                          )}
                        {data.kontent !== undefined &&
                          userData &&
                          userData.moderator &&
                          (data.kontent.status === "На оплату" ||
                            data.kontent.status === "Выполнено") && (
                            <button
                              className="btn btn-danger btn-block"
                              onClick={(e) => {
                                e.persist();
                                e.currentTarget.disabled = true;
                                data.kontent.status = "Модерация";

                                api
                                  .postData(data)
                                  .then((result) => {
                                    getDataServer();
                                  })
                                  .catch((err) => {
                                    console.log(`err`, err);
                                  })
                                  .finally(() => {
                                    e.target.disabled = false;
                                  });
                              }}
                            >
                              <span className="fs-14">Вернуть в модерацию</span>
                            </button>
                          )}
                        {data.kontent !== undefined ? (
                          !userData.moderator &&
                          (data.kontent.status === "На оплату" ||
                            data.kontent.status === "Выполнено") ? null : (
                            <button
                              className="btn btn-outline-primary"
                              onClick={(e) => {
                                e.persist();
                                e.currentTarget.disabled = true;
                                if (
                                  userData.moderator &&
                                  (data.kontent.status === "На оплату" ||
                                    data.kontent.status === "Выполнено")
                                )
                                  api
                                    .postData(data)
                                    .then((result) => {
                                      getDataServer();
                                    })
                                    .catch((err) => {
                                      console.log(`err`, err);
                                    })
                                    .finally(() => {
                                      e.target.disabled = false;
                                    });
                                else
                                  api
                                    .postContentData({ ...data.kontent })
                                    .then((result) => {
                                      getDataServer();
                                    })
                                    .catch((err) => {
                                      console.log(`err`, err);
                                    })
                                    .finally(() => {
                                      e.currentTarget.classList.remove(
                                        "deactive",
                                      );
                                    });
                              }}
                            >
                              <span className="fs-14">Сохранить</span>
                            </button>
                          )
                        ) : null}
                      </div>
                      {data.kontent !== undefined && (
                        <>
                          <div className="border-bottom"></div>
                          <div className="mt-3 lh-1">
                            <p className="m-0 fs-14 text-muted">
                              Правлено:{" "}
                              {moment(
                                data.kontent.updatedAt ||
                                  data.kontent.createdAt,
                              ).format("DD.MM.YYYY HH:mm")}{" "}
                            </p>
                            <p className="m-0 fs-14 text-muted">
                              {lastEditor !== null
                                ? "(" + lastEditor + " )"
                                : null}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card">
                    <div className="fs-14">
                      <div
                        className="chat mb-2 p-3"
                        style={{ height: "400px", overflowY: "auto" }}
                      >
                        {messagesHTML}
                      </div>
                      <div className="border-bottom"></div>
                      <textarea
                        className="m-0 fs-14 lh-1 p-3 w-100 border-0"
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.currentTarget.value)}
                      ></textarea>
                    </div>
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-primary"
                        style={{
                          borderTopLeftRadius: "0",
                          borderTopRightRadius: "0",
                        }}
                        onClick={() => {
                          if (data.kontent) {
                            if (!data.kontent.chat)
                              data.kontent.chat = { messages: [] };

                            data.kontent.chat.messages.push({
                              date: new Date(),
                              message: chatMessage,
                              fio: userData.fio,
                            });

                            api
                              .postContentData({ ...data.kontent })
                              .then((result) => {
                                getDataServer();
                                setChatMessage("");
                              })
                              .catch((err) => {
                                console.log(`err`, err);
                              });
                          }
                        }}
                      >
                        Написать{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    )
  );
}

export default Lk;
