import { Route, Switch, Redirect } from "react-router";
import Main from "./Main";
import Login from "./pages/Login";
import Lk from "./lk";
import Lk_two from "./lk2";
import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect } from "react";
import api from "./func/api";

function App() {
  const [activeProduct, setActiveProduct] = useState(false);
  const [userData, setUserData] = useState({});
  const [ispolnitel, setIspolnitel] = useState([]);

  useEffect(() => {
    api
      .getUserData()
      .then((result) => {
        setUserData({ ...result[0] });
      })
      .catch((err) => {
        console.log(`err`, err);
        setUserData(null);
      });
    api
      .getIspolnitel()
      .then((result) => {
        setIspolnitel(result);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, []);

  return (
    <div className="wrapper flex-nowrap position-relative">
      <Switch>
        <Route
          path="/login"
          exact
          render={(routProps) =>
            userData === null ? <Login /> : <Redirect to="/" />
          }
        />
        <Route
          path="/logout"
          exact
          render={(routProps) => {
            api
              .logout()
              .then((result) => {
                routProps.history.replace("/login");
              })
              .catch((err) => {
                console.log(`err`, err);
              });
          }}
        />
        {userData === null && <Redirect from="*" to="/login" />}
        <Route
          path="/"
          exact
          render={(routProps) => (
            <Main
              setActiveProduct={setActiveProduct}
              routProps={routProps}
              userData={userData}
              ispolnitel={ispolnitel}
            />
          )}
        />
        <Route
          path="/:slug"
          exact
          render={(routProps) => (
            <Lk
              slug={routProps.match.params.slug}
              activeProduct={activeProduct}
              userData={userData}
              ispolnitel={ispolnitel}
            />
          )}
        />

        {/* <Route path="/2" exact render={(routProps) => <Lk_two />} /> */}
      </Switch>
    </div>
  );
}

export default App;
